/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { getData } from "utils/Gateway";

//import images
import { assetImages } from "constants";
import { ecosystemSlug } from "Config/Config";
import { getAllCategories } from "helper/CategoryHelper/CategoryHelper";

// import use translation ------------------------------
import { useTranslation } from "react-i18next";

const Header = ({ isBlog, isSuccess }) => {
  const token = localStorage.getItem("token");

  const { t } = useTranslation();

  const location = useLocation(); // getting the route location

  const [categoryList, setCategoryList] = useState([]);

  //function for get all category
  const fetchAllCategories = async () => {
    try {
      const parentSlug = "blogpost";
      const response = await getAllCategories(ecosystemSlug, parentSlug);

      setCategoryList(response);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (isBlog) {
      fetchAllCategories();
    }
  }, [isBlog]);

  return (
    <header
      className={`home_header ${isBlog || isSuccess ? "news_header" : ""}`}
      style={{
        backgroundImage:
          isBlog || isSuccess ? `url(${assetImages.dotBlueBg})` : "",
      }}
    >
      {/* ----- main header section start ----- */}
      <div className="header_bttm">
        <div className="container">
          <div className="bttm_innr">
            {/* ----- logo section start ----- */}
            <div className="home_hdrlogo">
              <Link to="/">
                <img src="/images/logo.svg" alt="" />
              </Link>
            </div>
            {/* ----- logo section end ----- */}

            <div className="header_right">
              {/* ----- menu list section start with close icon.Close icon will appear in mobile version ------ */}
              <div className="menu_list">
                {/* ---- mobile menu close icon start ---- */}
                <button
                  className="menu_cross"
                  onClick={() => {
                    document.body.classList.remove("add_menupnl");
                  }}
                >
                  <span className="material-icons-outlined">close</span>
                </button>
                {/* ---- mobile menu close icon end ---- */}

                <ul className="list_stye_none">
                  {/* ----- connect li start ----- */}
                  <li
                    className={`${
                      location.pathname === "/connect" && "active"
                    }`}
                  >
                    <Link to="/connect">{t("Connect")}</Link>
                  </li>
                  {/* ----- connect li end ----- */}

                  {/* ----- devlopers li start ----- */}
                  <li
                    className={`${
                      location.pathname === "/develop" && "active"
                    }`}
                  >
                    <Link to="/develop">{t("Develop")}</Link>
                  </li>
                  {/* ----- devlopers li end ----- */}

                  {/* ------ Entrepreneurs li start ----- */}
                  <li
                    className={`${
                      location.pathname === "/entrepreneurs" && "active"
                    }`}
                  >
                    <Link to="/entrepreneurs">{t("Start")}</Link>
                  </li>
                  {/* ------ Entrepreneurs li end ----- */}

                  {/* ------ kit digital li start ----- */}
                  <li
                    className={`${
                      location.pathname === "/kitdigital" && "active"
                    }`}
                  >
                    <Link to="/kitdigital">{t("KIT Digital")}</Link>
                  </li>
                  {/* ------ kit digital li end ----- */}

                  {/* ------ sustainably li start ----- */}
                  <li
                    className={`${
                      location.pathname === "/sustainability" && "active"
                    }`}
                  >
                    <Link to="/sustainability">{t("Sustainability")}</Link>
                  </li>
                  {/* ------ sustainably li end ----- */}
                  {/* ------ success story li start ----- */}
                  <li
                    className={`${
                      location.pathname === "/successstories" && "active"
                    }`}
                  >
                    <Link to="/successstories">{t("Success Stories")}</Link>
                  </li>
                  {/* ------ success story li end ----- */}

                  {/* ------ blog li start ----- */}
                  <li
                    className={`${location.pathname === "/blog" && "active"}`}
                  >
                    <Link to="/blog">{t("Blog")}</Link>
                  </li>
                  {/* ------ blog li end ----- */}
                </ul>
              </div>
              {/* ----- menu list section start with close icon ------ */}

              {/* ------ login button start.If token is not exist then it will visible ----- */}
              {token ? null : (
                <div className="login_btns my_accountbtn">
                  <Link
                    to="#"
                    data-bs-target="#login_modal"
                    data-bs-toggle="modal"
                    className="btn white_btn"
                  >
                    {t("Login")}
                  </Link>
                </div>
              )}
              {/* ------ login button end ----- */}

              {/* ------ menu section for mobile version start ----- */}
              <div className="hamberger_menu">
                <Link
                  to="#"
                  onClick={() => {
                    document.body.classList.add("add_menupnl");
                  }}
                >
                  <img src={assetImages.menuIcon} alt="" />
                </Link>
              </div>
              {/* ------ menu section for mobile version end ----- */}
            </div>
          </div>
        </div>
      </div>
      {/* ----- main header section end ----- */}

      {/* ------ blog category list section start ------ */}
      {isBlog && (
        <div className="news_bottm_menu">
          <div className="container">
            <ul className="list_stye_none">
              {categoryList.map((category, index) => {
                return (
                  <li key={index}>
                    <Link to={`/blog/${category.slug}`}>{category.label}</Link>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      )}
      {/* ------ blog category list section end ------ */}

      <div className="overlay"></div>
    </header>
  );
};

export default Header;
