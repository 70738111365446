import React from "react";
import { Link } from "react-router-dom";
import * as url from "helper/UrlHelper";

const CompanyListCard = ({ company }) => {
  return (
    <div className="groups_item">
      <div className="top_company_row company_top">
        <div className="left_company">
          {/* ----- company name start ----- */}
          <div className="event_hdng">
            <h3>{company.name}</h3>
          </div>
          {/* ----- company name end ----- */}
          <div className="event_tags">
            <ul className="list_stye_none">
              {/* ----- company review and rating start ----- */}
              <li>
                <i className="material-icons-outlined ornge_icon">star</i>
                <span className="rating">
                  {company.totalratings} <em>({company.totalreviews})</em>
                </span>
              </li>
              {/* ----- company review and rating end ----- */}

              {/* ----- company location start ----- */}
              <li>
                <i className="material-icons-outlined">place</i>
                <span>
                  {company.city} , {company.state} , {company.country}
                </span>
              </li>
              {/* ----- company location end ----- */}
            </ul>
          </div>
        </div>
        {/* ----- company logo start ----- */}
        <div className="company_logo">
          <img
            className="thumb_logo"
            src={url.SERVER_URL + company.companylogo}
            alt=""
          />
        </div>
        {/* ----- company logo end ----- */}
      </div>

      {/* ----- company service start ----- */}

      <div className="event_para">
        <p>{company.servicestring}</p>
      </div>

      {/* ----- company service end ----- */}
      <div className="company_info_outer">
        {/* ----- company search start ----- */}

        {company.searchinglist.length > 0 ? (
          <div className="company_info_row">
            <label>Search</label>

            <div className="event_tags">
              <ul className="list_stye_none">
                {company.searchinglist.map((item, index) => {
                  return (
                    <li key={index}>
                      <Link to="#">{item.name}</Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        ) : null}
        {/* ----- company search end ----- */}

        {/* ----- company interest start ----- */}

        {company.interestlist.length > 0 ? (
          <div className="company_info_row">
            <label>Interest</label>

            <div className="event_tags">
              <ul className="list_stye_none">
                {company.interestlist.map((item, index) => {
                  return (
                    <li key={index}>
                      <Link to="#">{item.name}</Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        ) : null}
        {/* ----- company interest end ----- */}
      </div>
      <div className="details_btn">
        <Link to={`/company/details/${company._id}`} className="btn outline">
          View Details
        </Link>
      </div>
    </div>
  );
};

export default CompanyListCard;
