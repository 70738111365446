/* eslint-disable */
import Home from "../pages/Home/Home";
import Connect from "../pages/Connect/Connect";
import Develop from "../pages/Develop/Develop";
import Entrepreneurs from "../pages/Entrepreneurs/Entrepreneurs";
import KitDigital from "../pages/KitDigital/KitDigital";
import SuccessStoriesLanding from "../pages/SuccessStories/SuccessStoriesLanding/SuccessStoriesLanding";
import BlogDetails from "../pages/Blogs/BlogDetails/BlogDetails";
import BlogListing from "../pages/Blogs/BlogListing/BlogListing";

import Logout from "pages/Auth/Logout";
import FeedLanding from "pages/FeedLanding/FeedLanding";
import ActivitiesLanding from "pages/Activities/ActivitiesLanding/ActivitiesLanding";
import Notifications from "pages/Notifications/Notifications";
import Conversation from "pages/Conversation/Conversation";
import EventLanding from "pages/Events/EventLanding/EventLanding";
import EventDetails from "pages/Events/EventDetails/EventDetails";
import SuccessStoriesDetails from "pages/SuccessStories/SuccessStoriesDetails/SuccessStoriesDetails";
import GroupLanding from "pages/Groups/GroupLanding/GroupLanding";
import CourseLanding from "pages/Courses/CourseLanding/CourseLanding";
import JobLanding from "pages/Jobs/JobLanding/JobLanding";
import CompanyLanding from "pages/Company/CompanyLanding/CompanyLanding";
import GroupDetails from "pages/Groups/GroupDetails/GroupDetails";
import CourseDetails from "pages/Courses/CourseDetails/CourseDetails";
import JobDetails from "pages/Jobs/JobDetails/JobDetails";
import CompanyDetails from "pages/Company/CompanyDetails/CompanyDetails";
import BlogLanding from "pages/Blogs/BlogLanding/BlogLanding";
import Sustainability from "pages/Sustainability/Sustainability";
import MyProfile from "pages/MyProfile/MyProfile";
import Experience from "pages/Experience/Experience";
import ImprovementArea from "pages/ImprovementArea/ImprovementArea";
import PasswordDetails from "pages/Password/PasswordDetails/PasswordDetails";
import AccountSettings from "pages/AccountSettings/AccountSettings";
import Privacy from "pages/Privacy/Privacy";
import PasswordReset from "pages/Password/PasswordReset/PasswordReset";
import PasswordUpdate from "pages/Password/PasswordUpdate/PasswordUpdate";
import ActivitiesDetails from "pages/Activities/ActivitiesDetails/ActivitiesDetails";
import AppUserList from "pages/AppUser/AppUserList/AppUserList";
import AppUserDetails from "pages/AppUser/AppUserDetails/AppUserDetails";
import FriendRequestList from "pages/FriendRequestList/FriendRequestList";
import AuthValidate from "pages/Auth/AuthValidate";
import Pricing from "pages/Pricing/Pricing";

import Cart from "pages/Cart/Cart";
import PaymentSuccess from "pages/Payment/Success";
import PaymentFailure from "pages/Payment/Failure";
import CvBuilder from "pages/CvBuilder/CvBuilder";
import CustomerCvFeedbackBuilder from "pages/CustomerCvFeedback/CustomerCvFeedback";
import UserSurvey from "pages/UserSurvey/UserSurvey";
import MarketPlaceList from "pages/MarketPlace/MarketPlaceList/MarketPlaceList";
import MarketPlaceDetails from "pages/MarketPlace/MarketPlaceDetails/MarketPlaceDetails";
import BillingAddress from "pages/BillingAddress/BillingAddress";
import Checkout from "pages/Checkout/Checkout";
import FaqList from "pages/Faq/FaqList/FaqList";
import FaqDetails from "pages/Faq/FaqDetails/FaqDetails";

import MedicineList from "pages/Diagnosis/Medicines/ListMedicines";

/*======= public routes =======*/
const publicRoutes = [
  // { path: "/", component: HomeDigitalKit }, // home page
  { path: "/", component: Home }, // home page
  { path: "/connect", component: Connect }, // connect page
  { path: "/develop", component: Develop }, // develop page
  { path: "/entrepreneurs", component: Entrepreneurs }, // entrepreneurs page
  { path: "/kitdigital", component: KitDigital }, // kit digital page
  { path: "/successstories", component: SuccessStoriesLanding }, // success stories page
  { path: "/successstories/details/:id", component: SuccessStoriesDetails }, // success stories page
  { path: "/blog", component: BlogLanding }, // blogs landing page for all total categrory blogs
  { path: "/blog/:slug", component: BlogListing }, // blogs listing page for all blog by a single category
  { path: "/blogdetails/:id", component: BlogDetails }, // blogs details page
  { path: "/sustainability", component: Sustainability }, // Sustainability page
  { path: "/pricing", component: Pricing },
  { path: "/survey/invite/:id", component: UserSurvey },

  /* ======== validation start ======== */
  { path: "/auth/validate", component: AuthValidate }, //validate page
  /* ======== validation end ======== */
];
/*======= protected routes =======*/
const protectedRoutes = [
  /* ======== validation start ======== */
  { path: "/auth/validate", component: AuthValidate }, //validate page
  /* ======== validation end ======== */

  { path: "/logout", component: Logout },
  { path: "/feed", component: FeedLanding },
  { path: "/activities", component: ActivitiesLanding },
  { path: "/activities/details/:id", component: ActivitiesDetails },
  { path: "/invitelist", component: FriendRequestList },
  { path: "/notifications", component: Notifications },

  // room id = contact id , group id

  // conversationtype = contact , group , ai

  // componentslug = MOD_CONTACT , MOD_GROUP for identification in chat left panel line no 653

  //componentid is mongo id of component

  //componenttitle is title of component like The task title,challange title
  { path: "/conversation", component: Conversation },

  {
    path: "/conversation/:conversationtype/:roomid",
    component: Conversation,
  },
  {
    path: "/conversation/:conversationtype/:roomid/:moduleslug",
    component: Conversation,
  },
  {
    path: "/conversation/:conversationtype/:roomid/:moduleslug/:componentid",
    component: Conversation,
  },
  {
    path: "/conversation/:conversationtype/:roomid/:moduleslug/:componentid/:componentname",
    component: Conversation,
  },

  {
    path: "/conversation/:conversationtype/:roomid/:moduleslug/:componentid/:componentname",
    component: Conversation,
  },
  {
    path: "/conversation/:conversationtype/:roomid",
    component: Conversation,
  },

  {
    path: "/conversation/:conversationtype/:roomid/:componentslug",
    component: Conversation,
  },

  {
    path: "/conversation/:conversationtype/:roomid/:componentslug/:componentid",
    component: Conversation,
  },
  {
    path: "/conversation/:conversationtype/:roomid/:componentslug/:componentid/:componenttitle",
    component: Conversation,
  },

  // {
  //   path: "/conversation/:conversationtype/:conversationid/:componentslug",
  //   component: Conversation,
  // },
  // {
  //   path: "/conversation/:conversationtype/:conversationid/:componentslug/:componentname/:componenttitle/:componentid",
  //   component: Conversation,
  // },
  { path: "/peoples", component: AppUserList },
  { path: "/peoples/details/:id", component: AppUserDetails },
  { path: "/events", component: EventLanding },
  { path: "/events/details/:id", component: EventDetails },
  { path: "/groups", component: GroupLanding },
  { path: "/groups/details/:id", component: GroupDetails },
  { path: "/courses", component: CourseLanding },
  { path: "/courses/details/:id", component: CourseDetails },
  { path: "/jobs", component: JobLanding },
  { path: "/jobs/details/:id", component: JobDetails },
  { path: "/cvbuilder/:id", component: CvBuilder },
  { path: "/cvbuilder", component: CvBuilder },
  { path: "/mycvs", component: CvBuilder },
  { path: "/company", component: CompanyLanding },
  { path: "/company/details/:id", component: CompanyDetails },
  { path: "/profile", component: MyProfile },
  { path: "/experience", component: Experience },
  { path: "/improvementarea", component: ImprovementArea },
  { path: "/password", component: PasswordDetails },
  { path: "/passwordreset", component: PasswordReset },
  { path: "/passwordupdate", component: PasswordUpdate },
  { path: "/accountsettings", component: AccountSettings },
  { path: "/privacy", component: Privacy },
  { path: "/cart", component: Cart },
  { path: "/payment/success", component: PaymentSuccess },
  { path: "/payment/failure", component: PaymentFailure },
  { path: "/pricing", component: Pricing },
  { path: "/survey/invite/:id", component: UserSurvey },
  {
    path: "/jobresponse/clientfeedback/:id",
    component: CustomerCvFeedbackBuilder,
  },
  { path: "/marketplace", component: MarketPlaceList },
  { path: "/marketplace/details/:id", component: MarketPlaceDetails },

  { path: "/diagnosis/medicines", component: MedicineList },
  { path: "/billings", component: BillingAddress },
  { path: "/checkout", component: Checkout },
  // path for faq
  { path: "/faq", component: FaqList },

  // path for faq details
  { path: "/faq/details/:id", component: FaqDetails },
];

export { publicRoutes, protectedRoutes };
