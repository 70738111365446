/* eslint-disable */
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";

const AppUserFilterPopup = ({
  ecosystemList,
  skillList,
  serviceList,
  tagList,
  setfilterName,
  setfilterCompany,
  setFilterPosition,
  setFilterStartCost,
  setFilterEndCost,
  setfilterStartDate,
  setfilterEndDate,
  setFilterRating,
  setfilterSkills,
  setFilterServices,
  setFilterTags,
  setFilterEcosystems,
  resetList,
}) => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const [ecosystemValue, setEcosystemValue] = useState(null);
  const [selectedEcosystems, setselectedEcosystems] = useState([]);
  const [name, setname] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [position, setPosition] = useState("");
  const [fromCost, setfromCost] = useState("");
  const [toCost, settoCost] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedRating, setSelectedRating] = useState("");
  const [services, setServices] = useState([]);
  const [skills, setSkills] = useState([]);
  const [tags, setTags] = useState([]);

  /* hook for validation */
  const [validation, setValidation] = useState({
    dateWarning: false,
    costWarning: false,
    costDiffWarning: false,
  });

  /* function to validate form */
  const validationHandler = () => {
    let isValid = true;

    if (fromCost !== "" && toCost === "") {
      setValidation((prevState) => ({ ...prevState, costWarning: true }));
      isValid = false;
    }

    if (fromCost !== "" && toCost !== "") {
      if (parseInt(fromCost) > parseInt(toCost)) {
        setValidation((prevState) => ({ ...prevState, costDiffWarning: true }));
        isValid = false;
      }
    }

    if (startDate !== "" && endDate === "") {
      setValidation((prevState) => ({ ...prevState, dateWarning: true }));
      isValid = false;
    }

    return isValid;
  };

  //select ecosystem
  const ecosystemSelectionHandler = (val) => {
    setEcosystemValue(val);
    setselectedEcosystems(val.map((item) => item.value));
  };

  const handleRatingChange = (event) => {
    setSelectedRating(event.target.value);
  };

  //function for select service
  const serviceSelectionHandler = (data) => {
    let prevData = services;

    if (prevData.includes(data.value)) {
      let selectedItem = document.getElementById(
        "userservicefilter-" + data.value
      );
      selectedItem.classList.remove("active");
      // exist in array, thus remove
      let key = prevData.indexOf(data.value);
      prevData.splice(key, 1);
    } else {
      let selectedTagItem = document.getElementById(
        "userservicefilter-" + data.value
      );
      selectedTagItem.classList.add("active");
      // not exist in array, thus add
      prevData.push(data.value);
    }

    setServices(prevData);
  };

  // function for set tags
  const skillSelectionHandler = (data) => {
    let prevData = skills;

    if (prevData.includes(data.value)) {
      let selectedItem = document.getElementById("skilldata-" + data.value);
      selectedItem.classList.remove("active");
      // exist in array, thus remove
      let key = prevData.indexOf(data.value);
      prevData.splice(key, 1);
    } else {
      let selectedTagItem = document.getElementById("skilldata-" + data.value);
      selectedTagItem.classList.add("active");
      // not exist in array, thus add
      prevData.push(data.value);
    }

    setSkills(prevData);
  };

  //function for select tags
  const tagSelectionHandler = (data) => {
    let prevData = tags;

    if (prevData.includes(data.value)) {
      let selectedItem = document.getElementById("tagdata-" + data.value);
      selectedItem.classList.remove("active");
      // exist in array, thus remove
      let key = prevData.indexOf(data.value);
      prevData.splice(key, 1);
    } else {
      let selectedTagItem = document.getElementById("tagdata-" + data.value);
      selectedTagItem.classList.add("active");
      // not exist in array, thus add
      prevData.push(data.value);
    }

    setTags(prevData);
  };

  //function for remove active class for selected tags
  const removeActiveClass = () => {
    const liElements = document.querySelectorAll(".tags li a");
    liElements.forEach((li) => {
      li.classList.remove("active");
    });
  };

  //function for reset
  const resetSelectTags = () => {
    let resetBlock = document.getElementsByClassName("tagblock");
    for (let i = 0; i < resetBlock.length; i++) {
      resetBlock[i].classList.remove("active");
    }
  };

  const resetSelectServices = () => {
    let resetBlock = document.getElementsByClassName("skillblock");
    for (let i = 0; i < resetBlock.length; i++) {
      resetBlock[i].classList.remove("active");
    }
  };

  const resetSelectSkills = () => {
    let resetBlock = document.getElementsByClassName("serviceblock");
    for (let i = 0; i < resetBlock.length; i++) {
      resetBlock[i].classList.remove("active");
    }
  };

  //function for search
  const searchHandler = () => {
    if (validationHandler()) {
      setfilterName(name);
      setfilterCompany(companyName);
      setFilterPosition(position);
      setFilterRating(selectedRating);
      setFilterServices(services);
      setfilterSkills(skills);
      setFilterTags(tags);
      setFilterEcosystems(selectedEcosystems);

      if (fromCost !== "" && toCost !== "") {
        setFilterStartCost(fromCost);
        setFilterEndCost(toCost);
      } else {
        setFilterStartCost("");
        setFilterEndCost("");
      }

      if (startDate !== "" && endDate !== "") {
        setfilterStartDate(startDate);
        setfilterEndDate(endDate);
      } else {
        setfilterStartDate("");
        setfilterEndDate("");
      }

      // close the offcanvas of filter
      let offCanvasPopup = document.querySelector("#appuser_filter_offcanvas");
      let offCanvas = bootstrap.Offcanvas.getInstance(offCanvasPopup);
      offCanvas.hide();
      resetHandler();
    }
  };

  //function for reset
  const resetHandler = () => {
    setEcosystemValue(null);
    setselectedEcosystems([]);
    setname("");
    setCompanyName("");
    setPosition("");
    setfromCost("");
    settoCost("");
    setStartDate("");
    setEndDate("");
    setSelectedRating("");
    setTags([]);
    setServices([]);
    setSkills([]);
    removeActiveClass();
    resetSelectTags();
    resetSelectServices();
    resetSelectSkills();

    setValidation({
      dateWarning: false,
      costWarning: false,
      costDiffWarning: false,
    });
  };

  const reloadUser = () => {
    // close the offcanvas of filter
    let offCanvasPopup = document.querySelector("#appuser_filter_offcanvas");
    let offCanvas = bootstrap.Offcanvas.getInstance(offCanvasPopup);
    offCanvas.hide();
    resetList();
  };

  return (
    <div className="filter_canvas">
      <div className="offcanvas offcanvas-end" id="appuser_filter_offcanvas">
        <div className="offcanvas-header">
          <h3>FILTER</h3>
          <Link to="#" data-bs-dismiss="offcanvas">
            <i className="material-icons-outlined">close</i>
          </Link>
        </div>
        <div className="offcanvas-body">
          <div className="filter_outerbx">
            {/* ecosystem */}

            <div className="filter_item">
              <div className="filter_hdng d-flex align-items-center justify-content-between mb-3">
                <h4>Select Ecosystem</h4>
              </div>
              <div className="filter_information">
                <Select
                  isMulti
                  placeholder="Select Ecosystem"
                  options={ecosystemList}
                  value={ecosystemValue}
                  onChange={ecosystemSelectionHandler}
                />
              </div>
            </div>

            {/* company start */}
            <div className="filter_item">
              <div className="filter_hdng d-flex align-items-center justify-content-between mb-3">
                <h4>Company Name</h4>
              </div>
              <div className="filter_information">
                <div className="form-group d-flex gap-2">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Company"
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                  />
                </div>
              </div>
            </div>
            {/* company end */}

            {/* user name start */}
            <div className="filter_item">
              <div className="filter_hdng d-flex align-items-center justify-content-between mb-3">
                <h4>User Name</h4>
              </div>
              <div className="filter_information">
                <div className="form-group d-flex gap-2">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Name"
                    value={name}
                    onChange={(e) => setname(e.target.value)}
                  />
                </div>
              </div>
            </div>
            {/* user name end */}

            {/* postion */}
            <div className="filter_item">
              <div className="filter_hdng d-flex align-items-center justify-content-between mb-3">
                <h4>Position</h4>
              </div>
              <div className="filter_information">
                <div className="form-group d-flex gap-2">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Position"
                    value={position}
                    onChange={(e) => setPosition(e.target.value)}
                  />
                </div>
              </div>
            </div>

            {/* rating */}
            <div className="filter_item">
              <div className="filter_hdng d-flex align-items-center justify-content-between mb-3">
                <h4>Rating</h4>
              </div>
              <div className="filter_information">
                <ul className="list_stye_none filter_rating">
                  {[5, 4, 3, 2, 1].map((rating) => (
                    <li key={rating}>
                      <label className="radio-check">
                        <input
                          type="radio"
                          name="rdo3"
                          value={rating}
                          checked={selectedRating === String(rating)}
                          onChange={handleRatingChange}
                        />
                        <span></span>
                        <p>
                          {rating}{" "}
                          <i className="material-icons-outlined">star</i>
                          {rating === 5 ? "" : " & above"}
                        </p>
                      </label>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            {/* <div className="filter_item">
              <div className="filter_hdng d-flex align-items-center justify-content-between mb-3">
                <h4>Range Box</h4>
              </div>
              <div className="filter_information">
                <div className="form-group d-flex gap-2">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Position"
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Position"
                  />
                </div>
              </div>
            </div> */}

            {/* service */}
            <div className="filter_item">
              <div className="filter_hdng d-flex align-items-center justify-content-between mb-3">
                <h4>Services</h4>
              </div>
              <div className="filter_information">
                <div className="tags_list">
                  <div className="option_btns mt-2">
                    <ul className="list_stye_none d-flex flex-wrap align-items-center gap-2">
                      {serviceList.map((item, index) => {
                        return (
                          <li className="tag" key={index}>
                            <Link
                              to="#"
                              onClick={() => serviceSelectionHandler(item)}
                              id={"userservicefilter-" + item.value}
                              className="serviceblock d-flex align-items-center justify-content-center"
                            >
                              <span>{item.label}</span>
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {/* skills */}
            <div className="filter_item">
              <div className="filter_hdng d-flex align-items-center justify-content-between mb-3">
                <h4>Skills</h4>
              </div>
              <div className="filter_information">
                <div className="tags_list">
                  <div className="option_btns mt-2">
                    <ul className="list_stye_none d-flex flex-wrap align-items-center gap-2">
                      {skillList.map((item, index) => {
                        return (
                          <li className="tag" key={index}>
                            <Link
                              to="#"
                              onClick={() => skillSelectionHandler(item)}
                              id={"skilldata-" + item.value}
                              className="skillblock d-flex align-items-center justify-content-center"
                            >
                              <span>{item.label}</span>
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {/* label */}
            <div className="filter_item">
              <div className="filter_hdng d-flex align-items-center justify-content-between mb-3">
                <h4>Tags</h4>
              </div>
              <div className="filter_information">
                <div className="tags_list">
                  <div className="option_btns mt-2">
                    <ul className="list_stye_none d-flex flex-wrap align-items-center gap-2">
                      {tagList.map((item, index) => {
                        return (
                          <li className="tag" key={index}>
                            <Link
                              to="#"
                              onClick={() => tagSelectionHandler(item)}
                              id={"tagdata-" + item.value}
                              className="tagblock d-flex align-items-center justify-content-center"
                            >
                              <span>{item.label}</span>
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {/* date range  */}
            <div className="filter_item">
              <div className="filter_hdng d-flex align-items-center justify-content-between mb-3">
                <h4>Availibility</h4>
              </div>
              <div className="filter_information">
                <div className="form-group d-flex gap-2">
                  <input
                    type="date"
                    className="form-control"
                    placeholder="Start"
                    value={startDate}
                    onChange={(e) => {
                      setStartDate(e.target.value);
                      setValidation((prevState) => ({
                        ...prevState,
                        dateWarning: false,
                      }));
                    }}
                  />

                  <input
                    type="date"
                    className="form-control"
                    placeholder="End"
                    value={endDate}
                    onChange={(e) => {
                      setEndDate(e.target.value);
                      setValidation((prevState) => ({
                        ...prevState,
                        dateWarning: false,
                      }));
                    }}
                  />
                </div>
              </div>

              {validation.dateWarning && (
                <div className="error-message mt-3">
                  <p className="d-flex align-items-center gap-1 text-danger">
                    <span className="material-symbols-outlined">warning</span>
                    <span>Please enter end date!</span>
                  </p>
                </div>
              )}
            </div>

            {/* cost  */}
            <div className="filter_item">
              <div className="filter_hdng d-flex align-items-center justify-content-between mb-3">
                <h4>Cost</h4>
              </div>
              <div className="filter_information">
                <div className="form-group d-flex gap-2">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Start"
                    value={fromCost}
                    onChange={(e) => {
                      setfromCost(e.target.value);
                      setValidation((prevState) => ({
                        ...prevState,
                        costWarning: false,
                        costDiffWarning: false,
                      }));
                    }}
                  />

                  <input
                    type="number"
                    className="form-control"
                    placeholder="End"
                    value={toCost}
                    onChange={(e) => {
                      settoCost(e.target.value);
                      setValidation((prevState) => ({
                        ...prevState,
                        costWarning: false,
                        costDiffWarning: false,
                      }));
                    }}
                  />
                </div>
              </div>

              {validation.costWarning && (
                <div className="error-message mt-3">
                  <p className="d-flex align-items-center gap-1 text-danger">
                    <span className="material-symbols-outlined">warning</span>
                    <span>Please enter end cost!</span>
                  </p>
                </div>
              )}

              {validation.costDiffWarning && (
                <div className="error-message mt-3">
                  <p className="d-flex align-items-center gap-1 text-danger">
                    <span className="material-symbols-outlined">warning</span>
                    <span>End cost should be greater than start cost!</span>
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className="process_btns_outer d-flex align-items-center justify-content-between mt-3">
            <button className="btn gray" onClick={reloadUser}>
              Reset
            </button>
            <button className="btn" onClick={searchHandler}>
              Show Result
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppUserFilterPopup;
