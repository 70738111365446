/* eslint-disable */
import "react-multi-carousel/lib/styles.css";

import React, { useContext, useEffect, useState } from "react";

import { Link } from "react-router-dom";
// import use translation ------------------------------
import { useTranslation } from "react-i18next";

//import context
import { GlobalProvider } from "context/GlobalContext";

/*import url and gateway methods */
import { getData, putData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

import { assetImages } from "constants";

import GroupListPlaceHolder from "./PlaceHolder/GroupListPlaceHolder";
import GroupListCard from "./Card/GroupListCard";
import GroupListFilterPopup from "../Popup/GroupListFilterPopup";

const GroupLandingMiddlePanel = (start, limit = 100, sortingBy) => {
  const { t } = useTranslation(); // translation marker

  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const moduleAccess = localStorage.getItem("moduleaccess");

  const glowCount = [1, 2, 3];

  const [tagList, setTagList] = useState([]);
  const [categoryList, setcategoryList] = useState([]);
  const [searchingList, setsearchingList] = useState([]);
  const [interestList, setinterestList] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [groupList, setGroupList] = useState([]);

  const [filterRating, setFilterRating] = useState("");
  const [filterDateRange, setFilterDateRange] = useState("");
  const [filterGroupTitle, setFilterGroupTitle] = useState("");
  const [filterGroupCategories, setfilterGroupCategories] = useState([]);
  const [filterGroupInterests, setfilterGroupInterests] = useState([]);
  const [filterGroupSearchings, setfilterGroupSearchings] = useState([]);
  const [filterGroupTags, setFilterGroupTags] = useState([]);
  const [filterGroupCity, setfilterGroupCity] = useState("");
  const [filterGroupCountry, setfilterGroupCountry] = useState("");

  const [params, setParams] = useState(null);
  const [searchedText, setsearchedText] = useState("");
  const [hideNextButton, sethideNextButton] = useState(false);

  const [reloadData, setReloadData] = useState(false);

  //function for get all category
  const getAllCategories = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_CATEGORIES +
        `?token=${token}&slug=GROUP`;

      const response = await getData(requestUrl);

      if (response.status) {
        setcategoryList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const getAllSearchingInterest = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_CATEGORIES +
        `?token=${token}&slug=skills`;

      const response = await getData(requestUrl);

      if (response.status) {
        setsearchingList(response.data);
        setinterestList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all tags
  const getAllTags = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_TAGS + `?token=${token}`;

      const response = await getData(requestUrl);

      // console.log(response);

      if (response.status) {
        setTagList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all lead records
  const getAllGroups = async () => {
    try {
      setIsLoading(true);
      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_GROUPS + `?token=${token}`;

      if (filterGroupTitle != "") {
        requestUrl = requestUrl + `&filtertitle=${filterGroupTitle}`;
      }
      if (filterGroupCity != "") {
        requestUrl = requestUrl + `&filtercity=${filterGroupCity}`;
      }
      if (filterGroupCountry != "") {
        requestUrl = requestUrl + `&filtercountry=${filterGroupCountry}`;
      }
      if (filterDateRange != "") {
        requestUrl = requestUrl + `&filterdates=${filterDateRange}`;
      }
      if (filterRating != "") {
        requestUrl = requestUrl + `&filterrating=${filterRating}`;
      }
      if (filterGroupTags.length > 0) {
        requestUrl = requestUrl + `&filtertags=${filterGroupTags}`;
      }
      if (filterGroupCategories.length > 0) {
        requestUrl = requestUrl + `&filtercategory=${filterGroupCategories}`;
      }
      if (filterGroupInterests.length > 0) {
        requestUrl = requestUrl + `&filterinterest=${filterGroupInterests}`;
      }
      if (filterGroupSearchings.length > 0) {
        requestUrl = requestUrl + `&filtersearchings=${filterGroupSearchings}`;
      }

      console.log("url of group list---------->", requestUrl);

      const response = await getData(requestUrl);

      console.log("response in group list------->", response);

      setIsLoading(false);
      if (response.status) {
        setGroupList(response.data);

        if (response.data.length < limit) {
          sethideNextButton(true);
        }
        // resetFilterData();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for reset filter
  const resetFilterData = () => {
    setFilterDateRange("");
    setFilterGroupTitle("");
    setfilterGroupCity("");
    setfilterGroupCountry("");
    setFilterRating("");
    setFilterGroupTags([]);
    setfilterGroupCategories([]);
    setfilterGroupInterests([]);
    setfilterGroupSearchings([]);
  };

  const resetList = () => {
    sethideNextButton(false);
    setsearchedText("");
    setReloadData(true);
    // getAllGroups("0", "10", null);
  };

  useEffect(() => {
    if (reloadData) {
      getAllGroups("0", "10", null);
      setReloadData(false);
    }
  }, [reloadData]);

  //use effect for params
  useEffect(() => {
    if (params?.sortby) {
      getAllGroups("0", "10", params?.sortby);
    }
  }, [params]);

  //use effect for filter search
  useEffect(() => {
    if (
      filterDateRange != "" ||
      filterGroupTitle != "" ||
      filterGroupCity != "" ||
      filterGroupCountry != "" ||
      filterRating != "" ||
      filterGroupCategories.length > 0 ||
      filterGroupInterests.length > 0 ||
      filterGroupSearchings.length > 0 ||
      filterGroupTags.length > 0
    ) {
      getAllGroups("0", "10", null);
    }
  }, [
    filterDateRange,
    filterGroupTitle,
    filterRating,
    filterGroupCategories,
    filterGroupInterests,
    filterGroupSearchings,
    filterGroupTags,
    filterGroupCity,
    filterGroupCountry,
  ]);

  useEffect(() => {
    getAllTags();
    getAllCategories();
    getAllSearchingInterest();
    getAllGroups("0", "10", null);
  }, []);

  if (
    userInfo.role.slug === "ADMIN" ||
    userInfo.role.slug === "SUPER_ADMIN" ||
    moduleAccess.includes("MOD_GROUP")
  ) {
    return (
      <div className="pnl_mdlpnl">
        {/* <div className="back_btn">
        <Link to="#">
          <i className="material-icons-outlined">arrow_back</i>
          <span>{t('Back')}</span>
        </Link>
      </div> */}

        {/* <!-- ========== Start filter search Section ========== --> */}
        <div className="white_shadow_bx px-3 py-2 rounded-10 bg-white d-flex align-items-center justify-content-between mb-4 gap-3 filter_srch_item">
          <div className="filter_search_wrap d-flex align-items-center gap-3 w-100 p-3">
            <div className="dropdown">
              <Link
                to="#"
                className="dropdown-toggle text-gray"
                data-bs-toggle="dropdown"
              >
                Sort by
              </Link>
              <ul className="dropdown-menu px-2">
                <li className="mb-2">
                  <Link
                    to="#"
                    className="dropdown-item d-flex align-items-center gap-2 text-gray"
                    onClick={() => {
                      setParams({
                        sortby: "desc",
                      });
                    }}
                  >
                    {t("Rank")}
                    <i className="material-icons-outlined fs-sm">
                      arrow_upward
                    </i>
                  </Link>
                </li>
                <li className="mb-2">
                  <Link
                    to="#"
                    className="dropdown-item d-flex align-items-center gap-2 text-gray"
                    onClick={() => {
                      setParams({
                        sortby: "asc",
                      });
                    }}
                  >
                    {t("Rank")}
                    <i className="material-icons-outlined fs-sm">
                      arrow_downward
                    </i>
                  </Link>
                </li>
                <li>
                  <Link
                    to="#"
                    className="dropdown-item d-flex align-items-center gap-2 text-gray"
                    onClick={() => {
                      setParams({
                        sortby: "name",
                      });
                    }}
                  >
                    {t("Name")} (A-Z)
                  </Link>
                </li>
              </ul>
            </div>

            <div className="search_filter_form d-flex align-items-center justify-content-between w-100">
              <input
                type="text"
                className="form-control"
                placeholder="Search by group title"
                value={searchedText}
                onChange={(e) => {
                  setsearchedText(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    setFilterGroupTitle(searchedText);
                  }
                }}
              />
              <button
                className="filter_srch_btn"
                onClick={() => {
                  setFilterGroupTitle(searchedText);
                }}
              >
                <i className="material-icons-outlined">search</i>
              </button>
            </div>
          </div>
          <div className="filter_refresh_btn d-flex align-items-center gap-3">
            <Link
              to="#"
              className="d-block refresh_iocn"
              onClick={() => {
                resetFilterData();
                resetList();
              }}
            >
              <i className="material-icons-outlined">refresh</i>
            </Link>
            <Link
              to="#"
              // data-bs-toggle="offcanvas"
              // data-bs-target="#"
              role="button"
              // aria-controls="event_filter_offcanvas"
              className="d-flex align-items-center gap-2"
            >
              <i className="material-icons-outlined">tune</i>
              <span className="d-block">Filter</span>
            </Link>
          </div>
        </div>
        {/* <!-- ========== End filter search Section ========== --> */}

        <div className="post_item_outer">
          <div className="post_item">
            {isLoading ? (
              <div className="activity_innr">
                {glowCount.map((item, index) => {
                  return <GroupListPlaceHolder key={index} />;
                })}
              </div>
            ) : (
              <div className="activity_innr">
                {groupList.length > 0 ? (
                  groupList.map((groupData, index) => {
                    return <GroupListCard key={index} groupData={groupData} />;
                  })
                ) : (
                  <div className="empty_access text-center">
                    <div className="empty_pic mb-4">
                      {" "}
                      <img src={assetImages.emptyVector} alt="" />
                    </div>
                    <div className="empty_text">
                      <p className="fs-lg text-gray fw-semibold mb-4">
                        Sorry....! No groups availbale at this moment
                      </p>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>

        <GroupListFilterPopup
          tagList={tagList}
          categoryList={categoryList}
          searchingList={searchingList}
          interestList={interestList}
          setfilterGroupCategories={setfilterGroupCategories}
          setfilterGroupInterests={setfilterGroupInterests}
          setfilterGroupSearchings={setfilterGroupSearchings}
          setfilterGroupCity={setfilterGroupCity}
          setfilterGroupCountry={setfilterGroupCountry}
          setFilterDateRange={setFilterDateRange}
          setFilterGroupTags={setFilterGroupTags}
          setFilterGroupTitle={setFilterGroupTitle}
          setFilterRating={setFilterRating}
          resetList={resetList}
        />
      </div>
    );
  } else {
    return (
      <div className="pnl_mdlpnl">
        <div className="empty_access text-center">
          <div className="empty_pic mb-4">
            {" "}
            <img src={assetImages.emptyVector} alt="" />
          </div>
          <div className="empty_text">
            <p className="fs-lg text-gray fw-semibold mb-4">
              Sorry....! You don't have privilege to see this content
            </p>
          </div>
        </div>
      </div>
    );
  }
};

export default GroupLandingMiddlePanel;
