/* eslint-disable */
import React, { useEffect, useState, useRef, useContext } from "react";
import { Link } from "react-router-dom";

/*import url and gateway methods */
import { getData, postData, putData, uploadMultipleFile } from "utils/Gateway";
import * as url from "helper/UrlHelper";

//import context
import { GlobalProvider } from "context/GlobalContext";

import { assetImages } from "constants";
import { formatTimeAgo } from "utils/FormatTimeAgo";
import ConversationUploadModal from "components/ConversationComponents/Modals/ConversationUploadModal";
import { fileDownloadHandler } from "utils/DownloadFile";
import ChatRightPanelPlaceholder from "components/ConversationComponents/ConversationLeftPanel/Placeholder/ChatRightPanelPlaceholder";
import ChatMessageContent from "../ChatMessageContent/ChatMessageContent";
import { languageList } from "helper/Common/CommonHelper";
import ChatMessageFlag from "../ChatMessageFlag/ChatMessageFlag";
import { getModuleName } from "helper/ChatHelper/ChatHelper";
import ShowImageModal from "components/ConversationComponents/Modals/ShowImageModal";

const ConversationRightContactChatPanel = ({
  conversationName = "",
  selectedContactUserId,
  selectedChatEmail,
  selectedChatLogo,
  selectedChatRoomId,
  chatcomponentSlug,
  setchatcomponentSlug,
  chatComponentTitle,
  setchatComponentTitle,
  chatComponentId,
  setchatComponentId,
  socket,
  searchMessage = "",
  setsearchMessage = () => {},
  refreshMessages = false,
  setrefreshMessages = () => {},
  getSearchMessage = false,
  setgetSearchMessage = () => {},
}) => {
  const {
    reloadChatPanel,
    setreloadChatPanel,
    setReloadConversationLeftPanel,
    setisUnreadActive,
  } = useContext(GlobalProvider);

  const glowPlaceholderCount = [1, 2, 3];

  // const socket = useRef(null);
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  // assign useref hook for auto scrolling
  const messagesEndRef = useRef(null);
  const chatInputRef = useRef(null);
  const typingTimeoutRef = useRef(null);

  // socket.on("connect", () => {
  //   console.log("Connected! Socket ID:", socket.id); // Access socket.id when the connection is established
  // });

  const [isMessageLoading, setIsMessageLoading] = useState(false);
  const [chatMessages, setChatMessages] = useState([]);

  const [selectedLanguageObj, setselectedLanguageObj] = useState(null);

  const [flaggedLanguage, setFlaggedLanguage] = useState("");
  const [messageText, setMessageText] = useState("");
  const [modalMessageText, setmodalMessageText] = useState(""); //for upload file modal

  const [messageIndex, setmessageIndex] = useState(null);
  const [messageUpdating, setmessageUpdating] = useState(false);

  const [parentMessageId, setparentMessageId] = useState(null);
  const [replyUserName, setreplyUserName] = useState("");
  const [replyMessage, setreplyMessage] = useState("");

  const [isUploading, setisUploading] = useState(false);
  const [uploadedFileCount, setUploadedFileCount] = useState(0);
  const [messageWarning, setmessageWarning] = useState(false);
  const [fileIsBig, setFileIsBig] = useState(false);

  const [uploadedFiles, setuploadedFiles] = useState([]);
  const [uploadedFileIds, setuploadedFileIds] = useState([]);

  const [imagePath, setImagePath] = useState("");

  // start typing --
  const [isTyping, setIsTyping] = useState(false);
  const [typingUser, setTypingUser] = useState(null);

  const [afterRequireResponseMessage, setafterRequireResponseMessage] =
    useState(null);

  // function for scroll chat bottom behavior
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView();
  };

  //function upload multiple files
  const fileUploadHandler = async (e) => {
    try {
      setFileIsBig(false);

      setisUploading(true);

      const files = e.target.files;

      setUploadedFileCount(files.length);

      let fileProceed = false;

      if (files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          let f = files[i];

          if (f.size <= 12500000) {
            fileProceed = true;
          } else {
            fileProceed = false;
          }
        }
      }

      if (fileProceed) {
        const requestUrl =
          url.API_BASE_URL + url.API_MULTIPLE_FILE_UPLOAD + `?token=${token}`;

        const response = await uploadMultipleFile(requestUrl, files);

        setisUploading(false);

        console.log(response);

        if (response.status) {
          /*----- assign uploaded files with path for display start ------*/
          const uploadedFileValues = response.data.map(
            ({ name, path, _id, ...other }) => ({
              name,
              path: url.SERVER_URL + path,
              filepath: path,
              _id,
            })
          );

          //check old file exist or not if exist then new file will concat with old file
          if (uploadedFiles.length == 0) {
            setuploadedFiles(uploadedFileValues);
          } else {
            let fileArr = uploadedFiles.concat(uploadedFileValues);
            setuploadedFiles(fileArr);
          }
          /*----- assign uploaded files with path for display end ------*/

          // Clear the state before updating with new values
          // setuploadedFiles([]);
          // setuploadedFileIds([]);

          /*----- assign uploaded file ids start ------*/
          const fileIds = response.data.map(({ _id, ...other }) => ({ _id }));

          //check old file ids are exist or not.If exist then new id will concat with old id
          if (uploadedFileIds.length == 0) {
            setuploadedFileIds(fileIds);
          } else {
            let fileArr = uploadedFileIds.concat(fileIds);
            setuploadedFileIds(fileArr);
          }
          /*----- assign uploaded file ids end ------*/
          resetFile();
        }
      } else {
        setFileIsBig(true);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  // function for clear file value
  const resetFile = () => {
    setUploadedFileCount(0);
    const file = document.getElementById("uploadConversationFile");
    if (file) {
      file.value = null;
    } else {
      return;
    }
  };

  //function for delete file from upload stack
  const closeFileHandler = (index) => {
    const closedFile = [...uploadedFiles];
    const removedFileId = [...uploadedFileIds];

    closedFile.splice(index, 1);
    removedFileId.splice(index, 1);

    setuploadedFiles(closedFile);
    setuploadedFileIds(removedFileId);

    resetFile();
  };

  //get all message
  const getAllChatMessages = async (isLoading = false) => {
    try {
      isLoading && setIsMessageLoading(true);

      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_CHAT_MESSAGES +
        `?token=${token}&chatpersonid=${selectedContactUserId}`;

      if (searchMessage !== "") {
        requestUrl += `&searchmessage=${searchMessage}`;
      }

      const response = await getData(requestUrl);

      setIsMessageLoading(false);

      console.log("response in chat panel ---->", response);

      if (response.status) {
        setChatMessages(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //send chat message
  const sendMessageHandler = async () => {
    if (
      selectedContactUserId &&
      selectedChatEmail !== "" &&
      (messageText !== "" || modalMessageText !== "")
    ) {
      setsearchMessage("");

      setmessageUpdating(true);

      setisUnreadActive(false);
      document.title = "Conversation"; // Reset to default title

      const uploadedSenderFiles =
        uploadedFiles.length > 0
          ? uploadedFiles.map((file) => {
              return {
                ...file,
                path: file.filepath,
              };
            })
          : [];

      const messageObj = {
        _id: null,
        parent: parentMessageId,
        contactid: selectedChatRoomId,
        sender: userInfo._id,
        receiver: selectedContactUserId,
        sendermail: userInfo.email,
        receivermail: selectedChatEmail,
        description: messageText || modalMessageText,
        flaggeddescription: messageText || modalMessageText,
        moduleslug: "MOD_CONTACT",
        uploads: uploadedSenderFiles,
        component: chatcomponentSlug,
        componenttitle: chatComponentTitle,
        componentid: chatComponentId,
        sendername: `${userInfo.name ?? userInfo.email} ${
          userInfo.surname ?? ""
        }`,
        senderimage: userInfo.photoimage ? `${userInfo.photoimage?.path}` : "",
        updatedAt: Date.now(),
        createdAt: Date.now(),
        parentchatmessage: replyMessage,
        parentchatsender: replyUserName,
      };

      setChatMessages((prevMessages) => [...prevMessages, messageObj]);

      try {
        let messageData = {
          contactid: selectedChatRoomId,
          sender: userInfo._id,
          receiver: selectedContactUserId,
          parent: parentMessageId,
          sendermail: userInfo.email,
          receivermail: selectedChatEmail,
          description: messageText || modalMessageText,
          moduleslug: "MOD_CONTACT",
          uploads: uploadedFileIds,
          component: chatcomponentSlug,
          componenttitle: chatComponentTitle,
          componentid: chatComponentId,
          sendername: `${userInfo.name ?? userInfo.email} ${
            userInfo.surname ?? ""
          }`,
          senderimage: userInfo.photoimage
            ? `${userInfo.photoimage?.path}`
            : "",
          flaggedlanguage: flaggedLanguage,
        };

        console.log(messageData);

        resetChatBox();

        let requestUrl =
          url.API_BASE_URL + url.API_SEND_CHAT + `?token=${token}`;

        const response = await postData(requestUrl, messageData);

        setmessageUpdating(false);

        // console.log(response);

        if (response.status) {
          setReloadConversationLeftPanel(true);
          setreloadChatPanel(true);

          // setChatMessages((prevMessages) => [...prevMessages, response.data]);
          socket.emit("sendmessage", {
            chatroomid: selectedChatRoomId,
            messageData: response.data,
            receiverlist: [selectedContactUserId],
          });
        }

        setIsTyping(false);

        socket.emit("stoptyping", {
          chatroomid: selectedChatRoomId,
          senderid: userInfo._id,
          senderlabel: userInfo.name ?? userInfo.email,
        });
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  //update chat
  const updateChatMessageRequireResponse = async (chatId = null) => {
    try {
      setmessageUpdating(true);

      let chatData = {
        requireresponse: true,
      };

      let requestUrl =
        url.API_BASE_URL + url.API_UPDATE_CHAT + `/${chatId}?token=${token}`;

      const response = await putData(requestUrl, chatData);

      const contactData = {
        requireresponse: true,
      };

      const contactRequestUrl =
        url.API_BASE_URL +
        url.API_UPDATE_CONTACT +
        `/${selectedChatRoomId}?token=${token}`;

      await putData(contactRequestUrl, contactData);

      setmessageUpdating(false);

      if (response.status) {
        socket.emit("replacemessage", {
          chatroomid: selectedChatRoomId,
          messageData: response.data,
        });
        setreloadChatPanel(true);
        setafterRequireResponseMessage(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //delete chat
  const deleteChatHandler = async (chatId = null) => {
    if (chatId) {
      try {
        let messageData = {
          contactid: selectedChatRoomId,
          constactperson: selectedContactUserId,
        };

        let requestUrl =
          url.API_BASE_URL + url.API_DELETE_CHAT + `/${chatId}?token=${token}`;

        const response = await putData(requestUrl, messageData);

        console.log(response);

        if (response.status) {
          setReloadConversationLeftPanel(true);
          setreloadChatPanel(true);
          // getAllChatMessages(false);
        }
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  //update chat status
  const updateChatStatusHandler = async () => {
    setisUnreadActive(false);
    document.title = "Conversation"; // Reset to default title
    try {
      let chatStatusData = {
        group: null,
        contactid: selectedChatRoomId,
        personid: selectedContactUserId,
      };

      // console.log(chatStatusData);

      let requestURL =
        url.API_BASE_URL + url.API_UPDATE_TRACKERS + `?token=${token}`;

      const response = await putData(requestURL, chatStatusData);

      console.log(response);

      if (response.status) {
        setReloadConversationLeftPanel(true);
        socket.emit("refreshafterupdatestatus", {
          chatroomid: selectedChatRoomId,
        });
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  // paste image from clipboard
  const pasteFileHandler = async (event) => {
    const items = event.clipboardData.items;

    console.log("clipboard items", items);

    const files = [];

    for (let i = 0; i < items.length; i++) {
      if (items[i].kind === "file") {
        const file = items[i].getAsFile();
        if (file) {
          files.push(file);
        }
      }
    }

    try {
      console.log("files.length", files.length);

      if (files.length > 0) {
        const requestUrl =
          url.API_BASE_URL + url.API_MULTIPLE_FILE_UPLOAD + `?token=${token}`;

        const response = await uploadMultipleFile(requestUrl, files);

        console.log("handle paste file response", response);

        if (response.status) {
          /*----- assign uploaded files with path for display start ------*/
          const uploadedFileValues = response.data.map(
            ({ name, path, _id, ...other }) => ({
              name,
              path: url.SERVER_URL + path,
              filepath: path,
              _id,
            })
          );

          //check old file exist or not if exist then new file will concat with old file
          if (uploadedFiles.length == 0) {
            setuploadedFiles(uploadedFileValues);
          } else {
            let fileArr = uploadedFiles.concat(uploadedFileValues);
            setuploadedFiles(fileArr);
          }
          /*----- assign uploaded files with path for display end ------*/

          /*----- assign uploaded file ids start ------*/
          const fileIds = response.data.map(({ _id, ...other }) => ({ _id }));

          //check old file ids are exist or not.If exist then new id will concat with old id
          if (uploadedFileIds.length == 0) {
            setuploadedFileIds(fileIds);
          } else {
            let fileArr = uploadedFileIds.concat(fileIds);
            setuploadedFileIds(fileArr);
          }
          /*----- assign uploaded file ids end ------*/
          resetFile();
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //reset chat box
  const resetChatBox = () => {
    setMessageText("");
    setmodalMessageText("");
    setmessageIndex(null);
    setuploadedFileIds([]);
    setuploadedFiles([]);
    resetReplyHandler();
    resetFile();
    setFileIsBig(false);
  };

  const sendMessageByEnter = (e) => {
    if (e.key === "Enter") {
      sendMessageHandler();
    }
  };

  const resetReplyHandler = () => {
    setreplyMessage("");
    setreplyUserName("");
    setparentMessageId(null);
  };

  // typing event
  const typingHandler = () => {
    console.log("typing start");

    const typingInfo = {
      chatroomid: selectedChatRoomId,
      senderid: userInfo._id,
      senderlabel: userInfo.name ?? userInfo.email,
    };

    console.log("typingInfo", typingInfo);

    if (!isTyping) {
      console.log("typingInfo", typingInfo);

      setIsTyping(true);
      socket.emit("typing", typingInfo);
    }

    clearTimeout(typingTimeoutRef.current);
    typingTimeoutRef.current = setTimeout(() => {
      setIsTyping(false);
      socket.emit("stoptyping", typingInfo);
    }, 3000); // Adjust the delay as needed
  };

  useEffect(() => {
    if (
      selectedContactUserId &&
      selectedChatRoomId &&
      selectedChatEmail !== ""
    ) {
      getAllChatMessages(true);
    }
  }, [selectedContactUserId, selectedChatRoomId, selectedChatEmail]);

  useEffect(() => {
    if (reloadChatPanel) {
      // console.log(reloadChatPanel);
      // refresh chat

      getAllChatMessages(false);
      socket.emit("reloadchatlist", { chatroomid: selectedChatRoomId }); // Replace chatroomid with the actual room ID
      setreloadChatPanel(false);
    }
  }, [reloadChatPanel, selectedChatRoomId]);

  useEffect(() => {
    // Join the chatroom
    socket.emit("joinchat", selectedChatRoomId); // Replace chatroomid with the actual room ID

    // // Listen for incoming replace messages
    // socket.on("incomingreplacemessage", (messageData) => {
    //   // console.log("messageData", messageData);

    //   setChatMessages((prevMessages) => {
    //     // Find index of the message with updated data
    //     const index = prevMessages.findIndex(
    //       (message) => message._id.toString() === messageData._id.toString()
    //     );

    //     // Replace student record at the found index
    //     if (index !== -1) {
    //       return [
    //         ...prevMessages.slice(0, index),
    //         messageData,
    //         ...prevMessages.slice(index + 1),
    //       ];
    //     }

    //     // Return the previous state if the student was not found
    //     return prevMessages;
    //   });
    // });

    //listen for refresh chat list
    socket.on("refreshchatlist", () => {
      getAllChatMessages(false);
    });

    return () => {
      if (selectedChatRoomId) {
        setmessageIndex(null);
        socket.emit("leavechat", selectedChatRoomId); // Leave the chatroom on component unmount
        socket.off("incomingmessage"); // Clean up event listener on unmount if needed
      }
    };
  }, [selectedChatRoomId, socket]);

  // use effect to get who are typing -----------------------------
  useEffect(() => {
    socket.on("usertyping", ({ senderid, senderlabel }) => {
      console.log("usertyping received", senderid, senderlabel);

      setTypingUser({ id: senderid, label: senderlabel });
    });

    socket.on("userstoptyping", ({ senderid, senderlabel }) => {
      setTypingUser(null);
    });

    return () => {
      socket.off("usertyping");
      socket.off("userstoptyping");
    };
  }, [socket]);

  useEffect(() => {
    if (afterRequireResponseMessage) {
      // console.log("useeffect call");

      setChatMessages((prevMessages) => {
        // Find index of the message with updated data
        const index = prevMessages.findIndex(
          (message) =>
            message._id.toString() ===
            afterRequireResponseMessage._id.toString()
        );

        // Replace student record at the found index
        if (index !== -1) {
          return [
            ...prevMessages.slice(0, index),
            afterRequireResponseMessage,
            ...prevMessages.slice(index + 1),
          ];
        }

        // Return the previous state if the student was not found
        return prevMessages;
      });

      socket.emit("replacemessage", {
        chatroomid: selectedChatRoomId,
        messageData: afterRequireResponseMessage,
      });

      setafterRequireResponseMessage(null);
    }
  }, [afterRequireResponseMessage]);

  // search chat messages
  useEffect(() => {
    if (searchMessage !== "" && getSearchMessage) {
      getAllChatMessages(true);
      setgetSearchMessage(false);
    }
  }, [searchMessage, getSearchMessage]);

  // refresh chat messages
  useEffect(() => {
    if (refreshMessages) {
      getAllChatMessages(true);
      setrefreshMessages(false);
    }
  }, [refreshMessages]);

  useEffect(() => {
    if (typingUser) {
      scrollToBottom();
    }
  }, [typingUser]);

  // after new chat message added scroll to bottom
  useEffect(() => {
    scrollToBottom();
  }, [chatMessages]);

  if (isMessageLoading) {
    return (
      <div className="chating_mdlPnl grp_chat_Innr">
        {" "}
        <div className="chatting_innr">
          {glowPlaceholderCount.map((item, index) => {
            return <ChatRightPanelPlaceholder key={index} />;
          })}
        </div>
      </div>
    );
  } else {
    return (
      <>
        {/* polulate the chat data */}
        {chatMessages.length === 0 ? (
          <div className="chating_mdlPnl">
            <div className="chatting_innr chating_start">
              <div className="nothing_pic">
                <figure>
                  <img src={assetImages.emptyMessage} alt="" />
                </figure>
                <div className="nothing_caption">
                  <p>
                    {searchMessage !== ""
                      ? "Oops! messages not found"
                      : "Lets start discussion with a message"}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="chating_mdlPnl">
            <div className="chatting_innr pd_top_60">
              <div className="msg_outer msg_shareicon_info">
                {chatMessages.map((chatData, index) => {
                  return (
                    <div key={index}>
                      {/* reciever side */}
                      {chatData.receiver.toString() ===
                      userInfo._id.toString() ? (
                        <div className="msg_row group_usrmsg_row">
                          {/* <!-- ========== Start chat/group logo Section ========== --> */}
                          <div className="chat_infoLft">
                            <div className="msg_usr">
                              <img
                                src={
                                  selectedChatLogo === ""
                                    ? assetImages.defaultUser
                                    : url.SERVER_URL + selectedChatLogo
                                }
                                alt=""
                              />
                            </div>
                          </div>
                          {/* <!-- ========== End chat/group logo Section ========== --> */}

                          {/* <!-- ========== Start message content Section ========== --> */}
                          <div className="chat_msgright">
                            {/* <!-- ========== Start time Section ========== --> */}
                            <div className="grp_usrname_time">
                              <h5>
                                <span className="grp_usr_prsn me-2">
                                  {conversationName}
                                </span>
                                <span className="grp_usrTime">
                                  {formatTimeAgo(chatData.createdAt)}
                                </span>
                              </h5>
                            </div>
                            {/* <!-- ========== End time Section ========== --> */}

                            <div className="msg_text dropdown">
                              {/* <!-- ========== Start parent message Section ========== --> */}
                              {chatData.parent ? (
                                <div className="replied_msg_row">
                                  {/* <p>{chatData.parentchatmessage}</p> */}
                                  <ChatMessageContent
                                    message={chatData.parentchatmessage}
                                    flaggedLanguage=""
                                  />

                                  <span>By {chatData.parentchatsender}</span>
                                </div>
                              ) : null}
                              {/* <!-- ========== End parent message Section ========== --> */}

                              {/* <!-- ========== Start dropdown Section ========== --> */}
                              <Link
                                to="#"
                                data-bs-toggle="dropdown"
                                className="chat_expand_arws"
                                aria-expanded="false"
                              >
                                <span className="material-icons-outlined">
                                  {" "}
                                  expand_more{" "}
                                </span>
                              </Link>
                              {/* <!-- ========== End dropdown Section ========== --> */}

                              {/* <!-- ========== Start dropdown menu Section ========== --> */}
                              <ul className="dropdown-menu">
                                <li>
                                  <Link
                                    to="#"
                                    onClick={() => {
                                      chatInputRef.current.focus();
                                      if (
                                        chatData.flaggedlanguage &&
                                        chatData.flaggedlanguage !== ""
                                      ) {
                                        setreplyMessage(
                                          chatData.flaggeddescription
                                        );
                                      } else {
                                        setreplyMessage(chatData.description);
                                      }
                                      setreplyUserName(conversationName);
                                      setparentMessageId(chatData._id);
                                    }}
                                    className="d-flex align-items-center gap-2"
                                  >
                                    <i className="material-symbols-outlined fs-md">
                                      reply
                                    </i>
                                    <span>Reply</span>{" "}
                                  </Link>{" "}
                                </li>
                                {/* <li>
                                  <Link
                                    to="#"
                                    className="d-flex align-items-center gap-2"
                                  >
                                    <i className="material-symbols-outlined fs-md">
                                      {" "}
                                      translate{" "}
                                    </i>
                                    <span>Original Show</span>
                                  </Link>
                                </li> */}
                              </ul>
                              {/* <!-- ========== End dropdown menu Section ========== --> */}

                              <div className="replied_msg_text">
                                {/* <!-- ========== Start message Section ========== --> */}
                                <ChatMessageContent
                                  message={chatData.description}
                                />

                                {chatData.flaggedlanguage &&
                                chatData.flaggedlanguage !== "" ? (
                                  <div
                                    className="recever_translatebx d-flex gap-1 p-2 rounded-10 bg-white overflow-hidden mt-2"
                                    style={{ borderLeft: "2px solid #3191FF" }}
                                  >
                                    <span
                                      className="d-block rounded-circle overflow-hidden"
                                      style={{
                                        height: "20px",
                                        width: "20px",
                                        minWidth: "20px",
                                      }}
                                    >
                                      <ChatMessageFlag
                                        language={chatData.flaggedlanguage}
                                      />
                                    </span>
                                    <ChatMessageContent
                                      message={chatData.flaggeddescription}
                                    />
                                  </div>
                                ) : null}
                                {/* <p>{chatData.description}</p> */}
                                {/* <!-- ========== End message Section ========== --> */}

                                {/* <!-- ========== Start file Section ========== --> */}
                                {chatData.uploadedchatfiles &&
                                chatData.uploadedchatfiles.length > 0 ? (
                                  <div className="upload_innrBody msg_uploadbody mt-2">
                                    <div className="upload_outer">
                                      <div className="row">
                                        {chatData.uploadedchatfiles
                                          .slice(0, 6)
                                          .map((file, index2) => {
                                            const fileExtension = file.path
                                              .split(".")
                                              .pop();

                                            const fileObj = {
                                              ...file,
                                              path: url.SERVER_URL + file.path,
                                            };

                                            return (
                                              <div
                                                className="col"
                                                key={index2}
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                  if (
                                                    fileExtension === "jpg" ||
                                                    fileExtension === "jpeg" ||
                                                    fileExtension === "png" ||
                                                    fileExtension === "gif" ||
                                                    fileExtension === "bmp" ||
                                                    fileExtension === "tiff" ||
                                                    fileExtension === "tif" ||
                                                    fileExtension === "webp" ||
                                                    fileExtension === "heif" ||
                                                    fileExtension === "heic" ||
                                                    fileExtension === "raw" ||
                                                    fileExtension === "svg" ||
                                                    fileExtension === "apng" ||
                                                    fileExtension === "ico" ||
                                                    fileExtension === "exr"
                                                  ) {
                                                    setImagePath(fileObj.path);
                                                    //show image modal
                                                    let myModal =
                                                      new bootstrap.Modal(
                                                        document.getElementById(
                                                          "chatimagemodal"
                                                        )
                                                      );
                                                    myModal.show();
                                                  } else {
                                                    fileDownloadHandler(
                                                      fileObj
                                                    );
                                                  }
                                                }}
                                              >
                                                <div className="chat_uploadItem position-relative">
                                                  <div className="chat_upload_pic">
                                                    {fileExtension === "jpg" ||
                                                    fileExtension === "jpeg" ||
                                                    fileExtension === "png" ||
                                                    fileExtension === "gif" ||
                                                    fileExtension === "bmp" ||
                                                    fileExtension === "tiff" ||
                                                    fileExtension === "tif" ||
                                                    fileExtension === "webp" ||
                                                    fileExtension === "heif" ||
                                                    fileExtension === "heic" ||
                                                    fileExtension === "raw" ||
                                                    fileExtension === "svg" ||
                                                    fileExtension === "apng" ||
                                                    fileExtension === "ico" ||
                                                    fileExtension === "exr" ? (
                                                      <img
                                                        src={
                                                          url.SERVER_URL +
                                                          file.path
                                                        }
                                                        alt=""
                                                      />
                                                    ) : fileExtension ===
                                                      "pdf" ? (
                                                      <img
                                                        src={assetImages.PdfImg}
                                                        alt=""
                                                      />
                                                    ) : fileExtension ===
                                                        "xlsx" ||
                                                      fileExtension ===
                                                        "xlsm" ||
                                                      fileExtension ===
                                                        "xlsb" ||
                                                      fileExtension ===
                                                        "xltx" ||
                                                      fileExtension ===
                                                        "xls" ? (
                                                      <img
                                                        src={assetImages.XlsImg}
                                                        alt=""
                                                      />
                                                    ) : (
                                                      <img
                                                        src={assetImages.DocImg}
                                                        alt=""
                                                      />
                                                    )}
                                                  </div>
                                                  <div className="chat_upload_caption">
                                                    <p>
                                                      <span>{file.name}</span>
                                                    </p>
                                                  </div>
                                                  {index2 === 5 &&
                                                  chatData.uploadedchatfiles
                                                    .length -
                                                    6 !==
                                                    0 ? (
                                                    <div className="overlay_text position-absolute">
                                                      <Link
                                                        to="#"
                                                        className="d-flex h-100 w-100 align-items-center justify-content-center"
                                                      >
                                                        {chatData
                                                          .uploadedchatfiles
                                                          .length - 6}
                                                        +
                                                      </Link>
                                                    </div>
                                                  ) : null}
                                                </div>
                                              </div>
                                            );
                                          })}
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                                {/* <!-- ========== End file Section ========== --> */}
                              </div>
                            </div>

                            {/* <!-- ========== Start component Section ========== --> */}
                            <div className="d-flex gap-2">
                              {chatData.component &&
                              chatData.component !== "" ? (
                                <p>
                                  Component :{" "}
                                  <span className="text-primary fs-sm">
                                    {getModuleName(chatData.component)}
                                  </span>
                                </p>
                              ) : null}{" "}
                              {chatData.componenttitle &&
                              chatData.componenttitle !== "" ? (
                                <p>
                                  Title :{" "}
                                  <span className="text-secondary fs-sm">
                                    {chatData.componenttitle}
                                  </span>
                                </p>
                              ) : null}
                              <Link
                                to="#"
                                className={
                                  chatData.requireresponse
                                    ? "btn-secondary text-white mb-0 px-2 py-1"
                                    : "d-none"
                                }
                              >
                                Require Response
                              </Link>
                            </div>
                            {/* <!-- ========== End component Section ========== --> */}
                          </div>
                          {/* <!-- ========== End message content Section ========== --> */}
                        </div>
                      ) : (
                        <div className="msg_row own_msgRow grp_ownmsg">
                          <div className="chat_msgright">
                            {/* <!-- ========== Start time Section ========== --> */}
                            <div className="chat_info_timeOuter">
                              <h5>{formatTimeAgo(chatData.updatedAt)}</h5>
                            </div>
                            {/* <!-- ========== End time Section ========== --> */}

                            {/* <!-- ========== Start message content Section ========== --> */}
                            <div className="owntext_row">
                              <div className="own_msgArea dropdown">
                                {/* <!-- ========== Start parent message Section ========== --> */}
                                {chatData.parent ? (
                                  <div className="replied_msg_row">
                                    {/* <p>{chatData.parentchatmessage}</p> */}
                                    <ChatMessageContent
                                      message={chatData.parentchatmessage}
                                    />
                                    <span>By {chatData.parentchatsender}</span>
                                  </div>
                                ) : null}
                                {/* <!-- ========== End parent message Section ========== --> */}

                                {/* <!-- ========== Start dropdown Section ========== --> */}
                                <Link
                                  to="#"
                                  data-bs-toggle="dropdown"
                                  className="chat_expand_arws"
                                  aria-expanded="false"
                                >
                                  <span className="material-icons-outlined">
                                    {" "}
                                    expand_more{" "}
                                  </span>
                                </Link>
                                {/* <!-- ========== End dropdown Section ========== --> */}

                                {/* <!-- ========== Start dropdown menu Section ========== --> */}
                                <ul className="dropdown-menu">
                                  <li>
                                    {messageUpdating &&
                                    messageIndex === index ? (
                                      <div
                                        className="mx-2 spinner-border spinner-border-sm"
                                        role="status"
                                      >
                                        <span className="visually-hidden">
                                          Loading...
                                        </span>
                                      </div>
                                    ) : (
                                      <Link
                                        to="#"
                                        onClick={() => {
                                          setmessageIndex(index);
                                          updateChatMessageRequireResponse(
                                            chatData._id
                                          );
                                        }}
                                        className="d-flex align-items-center gap-2"
                                      >
                                        <i className="material-symbols-outlined fs-md">
                                          reply
                                        </i>
                                        <span>Require Response</span>
                                      </Link>
                                    )}{" "}
                                  </li>
                                  <li>
                                    <Link
                                      to="#"
                                      onClick={() => {
                                        chatInputRef.current.focus();
                                        setreplyMessage(chatData.description);
                                        setreplyUserName("You");
                                        setparentMessageId(chatData._id);
                                      }}
                                      className="d-flex align-items-center gap-2"
                                    >
                                      <i className="material-symbols-outlined fs-md">
                                        reply
                                      </i>
                                      <span>Reply</span>{" "}
                                    </Link>{" "}
                                  </li>
                                  {chatData.sender.toString() ===
                                  userInfo._id.toString() ? (
                                    <li>
                                      <Link
                                        to="#"
                                        onClick={() => {
                                          deleteChatHandler(chatData._id);
                                        }}
                                        className="d-flex align-items-center gap-2"
                                      >
                                        <i className="material-symbols-outlined fs-md">
                                          delete
                                        </i>
                                        <span>Delete</span>{" "}
                                      </Link>{" "}
                                    </li>
                                  ) : null}
                                </ul>
                                {/* <!-- ========== End dropdown menu Section ========== --> */}

                                <div className="replied_msg_text">
                                  <ChatMessageContent
                                    message={chatData.description}
                                  />

                                  {chatData.flaggedlanguage &&
                                  chatData.flaggedlanguage !== "" ? (
                                    <div
                                      className="replied_msg_row recever_translatebx d-flex gap-1 p-2 rounded-10 overflow-hidden mt-2"
                                      style={{
                                        borderLeft: "2px solid #3191FF",
                                      }}
                                    >
                                      <span
                                        className="d-block rounded-circle overflow-hidden"
                                        style={{
                                          height: "20px",
                                          width: "20px",
                                          minWidth: "20px",
                                        }}
                                      >
                                        <ChatMessageFlag
                                          language={chatData.flaggedlanguage}
                                        />
                                      </span>
                                      <ChatMessageContent
                                        message={chatData.flaggeddescription}
                                      />
                                    </div>
                                  ) : null}
                                </div>

                                {chatData.uploadedchatfiles &&
                                chatData.uploadedchatfiles.length > 0 ? (
                                  <div className="upload_innrBody msg_uploadbody mt-2">
                                    <div className="upload_outer">
                                      <div className="row">
                                        {chatData.uploadedchatfiles
                                          .slice(0, 6)
                                          .map((file, index2) => {
                                            const fileExtension = file.path
                                              .split(".")
                                              .pop();

                                            const fileObj = {
                                              ...file,
                                              path: url.SERVER_URL + file.path,
                                            };

                                            return (
                                              <div
                                                className="col"
                                                key={index2}
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                  if (
                                                    fileExtension === "jpg" ||
                                                    fileExtension === "jpeg" ||
                                                    fileExtension === "png" ||
                                                    fileExtension === "gif" ||
                                                    fileExtension === "bmp" ||
                                                    fileExtension === "tiff" ||
                                                    fileExtension === "tif" ||
                                                    fileExtension === "webp" ||
                                                    fileExtension === "heif" ||
                                                    fileExtension === "heic" ||
                                                    fileExtension === "raw" ||
                                                    fileExtension === "svg" ||
                                                    fileExtension === "apng" ||
                                                    fileExtension === "ico" ||
                                                    fileExtension === "exr"
                                                  ) {
                                                    setImagePath(
                                                      url.SERVER_URL + file.path
                                                    );
                                                    //show image modal
                                                    let myModal =
                                                      new bootstrap.Modal(
                                                        document.getElementById(
                                                          "chatimagemodal"
                                                        )
                                                      );
                                                    myModal.show();
                                                  } else {
                                                    fileDownloadHandler(
                                                      fileObj
                                                    );
                                                  }
                                                }}
                                              >
                                                <div className="chat_uploadItem position-relative">
                                                  <div className="chat_upload_pic">
                                                    {fileExtension === "jpg" ||
                                                    fileExtension === "jpeg" ||
                                                    fileExtension === "png" ||
                                                    fileExtension === "gif" ||
                                                    fileExtension === "bmp" ||
                                                    fileExtension === "tiff" ||
                                                    fileExtension === "tif" ||
                                                    fileExtension === "webp" ||
                                                    fileExtension === "heif" ||
                                                    fileExtension === "heic" ||
                                                    fileExtension === "raw" ||
                                                    fileExtension === "svg" ||
                                                    fileExtension === "apng" ||
                                                    fileExtension === "ico" ||
                                                    fileExtension === "exr" ? (
                                                      <img
                                                        src={
                                                          url.SERVER_URL +
                                                          file.path
                                                        }
                                                        alt=""
                                                      />
                                                    ) : fileExtension ===
                                                      "pdf" ? (
                                                      <img
                                                        src={assetImages.PdfImg}
                                                        alt=""
                                                      />
                                                    ) : fileExtension ===
                                                        "xlsx" ||
                                                      fileExtension ===
                                                        "xlsm" ||
                                                      fileExtension ===
                                                        "xlsb" ||
                                                      fileExtension ===
                                                        "xltx" ||
                                                      fileExtension ===
                                                        "xls" ? (
                                                      <img
                                                        src={assetImages.XlsImg}
                                                        alt=""
                                                      />
                                                    ) : (
                                                      <img
                                                        src={assetImages.DocImg}
                                                        alt=""
                                                      />
                                                    )}
                                                  </div>
                                                  <div className="chat_upload_caption">
                                                    <p>
                                                      <span>{file.name}</span>
                                                    </p>
                                                  </div>
                                                  {index2 === 5 &&
                                                  chatData.uploadedchatfiles
                                                    .length -
                                                    6 !==
                                                    0 ? (
                                                    <div className="overlay_text position-absolute">
                                                      <Link
                                                        to="#"
                                                        className="d-flex h-100 w-100 align-items-center justify-content-center"
                                                      >
                                                        {chatData
                                                          .uploadedchatfiles
                                                          .length - 6}
                                                        +
                                                      </Link>
                                                    </div>
                                                  ) : null}
                                                </div>
                                              </div>
                                            );
                                          })}
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            {/* <!-- ========== End message content Section ========== --> */}

                            {/* <!-- ========== Start component chat name Section ========== --> */}
                            <div className="d-flex gap-2 justify-content-end">
                              {chatData.component &&
                              chatData.component !== "" ? (
                                <p>
                                  Component :{" "}
                                  <span className="text-primary fs-sm">
                                    {getModuleName(chatData.component)}
                                  </span>
                                </p>
                              ) : null}{" "}
                              {chatData.componenttitle &&
                              chatData.componenttitle !== "" ? (
                                <p>
                                  Title :{" "}
                                  <span className="text-secondary fs-sm">
                                    {chatData.componenttitle}
                                  </span>
                                </p>
                              ) : null}
                              <Link
                                to="#"
                                className={
                                  chatData.requireresponse
                                    ? "btn-secondary text-white mb-0 px-2 py-1"
                                    : "d-none"
                                }
                              >
                                Require Response
                              </Link>
                            </div>
                            {/* <!-- ========== End component chat name Section ========== --> */}
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })}

                {/* when person is thinking or replying */}
                {typingUser && (
                  <div className="msg_row group_usrmsg_row mt-2">
                    <div className="chat_infoLft">
                      <div className="msg_usr"></div>
                      <div className="chat_msgright me-1">
                        <div className="grp_usrname_time">
                          <h5>
                            <span className="grp_usr_prsn">
                              {typingUser.label} is typing
                            </span>
                          </h5>
                        </div>
                      </div>
                      <div className="chat_info_timeOuter">
                        <div className="chat_dots">
                          <img src="/images/dots-loading.gif" alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {/* ---- calling useref hook for scroll behaviour ----- */}
                <div ref={messagesEndRef} name="scrollRefDiv" />
              </div>
            </div>

            {parentMessageId || uploadedFiles.length > 0 || isUploading ? (
              <div className="reply_upload_wrapper">
                <div className="upload_files_wrapper p-3">
                  {/* <!-- ========== Start loading Section ========== --> */}
                  {isUploading ? (
                    <div className="uploading_file_counting d-flex align-items-center gap-2">
                      <div className="upload_icon_text d-flex align-items-center gap-2">
                        <i className="material-symbols-outlined">
                          arrow_upload_ready
                        </i>
                        <span>Uploading...</span>
                      </div>
                      <h5>{uploadedFileCount} files</h5>
                    </div>
                  ) : null}
                  {/* <!-- ========== End loading Section ========== --> */}

                  {/* <!-- ========== Start uploaded file Section ========== --> */}
                  {uploadedFiles.length > 0 ? (
                    <div className="upload_innrBody">
                      <div className="upload_outer">
                        <div className="row">
                          {uploadedFiles.map((file, index) => {
                            const fileExtension = file.path.split(".").pop();

                            return (
                              <div className="col" key={index}>
                                <div className="chat_uploadItem">
                                  <div
                                    className="chat_upload_pic"
                                    style={{
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      if (
                                        fileExtension === "jpg" ||
                                        fileExtension === "jpeg" ||
                                        fileExtension === "png" ||
                                        fileExtension === "gif" ||
                                        fileExtension === "bmp" ||
                                        fileExtension === "tiff" ||
                                        fileExtension === "tif" ||
                                        fileExtension === "webp" ||
                                        fileExtension === "heif" ||
                                        fileExtension === "heic" ||
                                        fileExtension === "raw" ||
                                        fileExtension === "svg" ||
                                        fileExtension === "apng" ||
                                        fileExtension === "ico" ||
                                        fileExtension === "exr"
                                      ) {
                                        setImagePath(
                                          url.SERVER_URL + file.path
                                        );
                                        //show image modal
                                        let myModal = new bootstrap.Modal(
                                          document.getElementById(
                                            "chatimagemodal"
                                          )
                                        );
                                        myModal.show();
                                      } else {
                                        fileDownloadHandler(fileObj);
                                      }
                                    }}
                                  >
                                    {fileExtension === "jpg" ||
                                    fileExtension === "jpeg" ||
                                    fileExtension === "png" ||
                                    fileExtension === "gif" ||
                                    fileExtension === "bmp" ||
                                    fileExtension === "tiff" ||
                                    fileExtension === "tif" ||
                                    fileExtension === "webp" ||
                                    fileExtension === "heif" ||
                                    fileExtension === "heic" ||
                                    fileExtension === "raw" ||
                                    fileExtension === "svg" ||
                                    fileExtension === "apng" ||
                                    fileExtension === "ico" ||
                                    fileExtension === "exr" ? (
                                      <img src={file.path} alt="" />
                                    ) : fileExtension === "pdf" ? (
                                      <img src={assetImages.PdfImg} alt="" />
                                    ) : fileExtension === "xlsx" ||
                                      fileExtension === "xlsm" ||
                                      fileExtension === "xlsb" ||
                                      fileExtension === "xltx" ||
                                      fileExtension === "xls" ? (
                                      <img src={assetImages.XlsImg} alt="" />
                                    ) : (
                                      <img src={assetImages.DocImg} alt="" />
                                    )}
                                  </div>

                                  <div className="chat_upload_caption">
                                    <p>
                                      <span>{file.name}</span>
                                    </p>
                                    <Link
                                      to="#"
                                      className="upoad_cross"
                                      onClick={() => closeFileHandler(index)}
                                    >
                                      <i className="material-icons-round">
                                        close
                                      </i>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {/* <!-- ========== End uploaded file Section ========== --> */}
                </div>

                {/* <!-- ========== Start reply Section ========== --> */}
                {parentMessageId ? (
                  <div className="reply_popup_area">
                    <div className="reply_popupmsg">
                      <h5>{replyUserName}</h5>
                      <p>{replyMessage}</p>
                    </div>
                    <div className="reply_cross">
                      <Link to="#" onClick={resetReplyHandler}>
                        <i className="material-icons-round">close</i>
                      </Link>
                    </div>
                  </div>
                ) : null}
                {/* <!-- ========== End reply Section ========== --> */}
              </div>
            ) : null}

            {/* <!-- ========== End reply Section ========== --> */}
          </div>
        )}

        {/* chat message box */}
        <div className="chat_sendPnl">
          <div className="emoji_iconArea">
            <ul className="list_stye_none">
              {/* <li>
                <Link
                  to="#"
                  data-bs-toggle="modal"
                  data-bs-target="#upload_modal"
                >
                  <span className="material-icons-round">upload_file</span>
                </Link>
              </li> */}
              <li>
                <a href="#" className="position-relative">
                  <span className="material-icons-round">upload_file</span>
                  <input
                    id="uploadConversationFile"
                    type="file"
                    className="upload_fileBtn"
                    multiple
                    onChange={fileUploadHandler}
                  />
                </a>
              </li>
              <li className="dropdown">
                <Link
                  to="#"
                  className="ms-2 d-flex align-items-center gap-1 dropdown-toggle"
                  data-bs-toggle="dropdown"
                >
                  {selectedLanguageObj ? (
                    <i>
                      <img src={selectedLanguageObj.flagimageurl} alt="" />
                    </i>
                  ) : (
                    <i className="material-symbols-outlined fs-md">globe</i>
                  )}

                  {selectedLanguageObj ? (
                    <span>{selectedLanguageObj.label}</span>
                  ) : (
                    <span>LN</span>
                  )}
                </Link>
                <ul className="dropdown-menu">
                  {languageList.map((item, index) => (
                    <li key={index}>
                      <Link
                        to="#"
                        className="d-flex align-items-center gap-1"
                        onClick={() => {
                          setselectedLanguageObj(item);
                          setFlaggedLanguage(item.value);
                        }}
                      >
                        <i>
                          <img src={item.flagimageurl} alt="" />
                        </i>
                        <span>{item.label}</span>
                      </Link>
                    </li>
                  ))}
                  <li>
                    <Link
                      to="#"
                      className="d-flex align-items-center gap-1"
                      onClick={() => {
                        setselectedLanguageObj(null);
                        setFlaggedLanguage("");
                      }}
                    >
                      <i className="material-symbols-outlined fs-md">close</i>
                      <span>Clear</span>
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div className="send_textbx ms-3">
            {/* {typingUser && <div>{typingUser.label} is typing...</div>} */}
            <form onSubmit={(e) => e.preventDefault()}>
              <input
                ref={chatInputRef}
                type="text"
                value={messageText}
                onChange={(e) => setMessageText(e.target.value)}
                className="form-control"
                placeholder="Enter your message"
                onKeyDown={sendMessageByEnter}
                onClick={updateChatStatusHandler}
                onPaste={pasteFileHandler}
                onInput={typingHandler}
              />
              <button
                type="button"
                className="chat_send_btn"
                onClick={sendMessageHandler}
              >
                <span className="material-icons-round">send</span>
              </button>
            </form>
          </div>
        </div>

        {/* <ConversationUploadModal
          messageUpdating={messageUpdating}
          sendMessageHandler={sendMessageHandler}
          sendMessageByEnter={sendMessageByEnter}
          modalMessageText={modalMessageText}
          setmodalMessageText={setmodalMessageText}
          uploadedFiles={uploadedFiles}
          setuploadedFiles={setuploadedFiles}
          uploadedFileIds={uploadedFileIds}
          setuploadedFileIds={setuploadedFileIds}
        /> */}

        <ShowImageModal imagePath={imagePath} setImagePath={setImagePath} />
      </>
    );
  }
};

export default ConversationRightContactChatPanel;
