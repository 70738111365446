/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

// import use translation ------------------------------
import { useTranslation } from "react-i18next";

/*import url and gateway methods */
import { getData, putData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

import AuthContactPanel from "components/common/AuthContactPanel";
import SaveFaqModal from "./Modal/SaveFaqModal";

const FaqListBody = () => {
  // user information
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const faqListRef = useRef(null);

  // props states for FaqSearch and FaqSearchResult
  const [searchQuery, setSearchQuery] = useState("");
  const [resetQuery, setResetQuery] = useState(false);

  // states for faq search result
  const [isLoading, setIsLoading] = useState(false);
  const [faqList, setFaqList] = useState([]);
  const [page, setPage] = useState(0);

  //initial for paginations
  const [totalPages, setTotalPages] = useState([]);
  const [lastPageNo, setLastPageNo] = useState(1);

  //function for set next page
  const nextPage = (data) => {
    setPage(data);
  };

  //function for blogref toggle
  const faqRefToggle = () => {
    faqListRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  // get request to API to fetch faq data
  const getAllFaqs = async () => {
    setIsLoading(true);
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_FAQ_LIST +
        `?token=${token}&modulefrom=frontend`;

      if (searchQuery.length > 2 && resetQuery === false) {
        requestUrl += `&filterquery=${searchQuery}`;
      }

      console.log("requestUrl", requestUrl);

      const response = await getData(requestUrl);

      setIsLoading(false);

      console.log("FAQ data response", response);

      if (response.status) {
        setFaqList(response.data);

        // // set last page no from API
        // setLastPageNo(response.totalpages);

        // /*--- Creating dynamic array for pagination ----*/
        // let pageArray = [];

        // for (let i = 1; i <= response.totalpages; i++) {
        //   pageArray.push(i);
        // }
        // setTotalPages(pageArray);
        // /*--- Creating dynamic array for pagination end ----*/
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (resetQuery) {
      getAllFaqs();
      setResetQuery(false); // after reload, set this false
    }
  }, [resetQuery]);

  useEffect(() => {
    getAllFaqs();
  }, []);

  return (
    <section className="feed_pnltotal">
      <div className="container-fluid">
        <div className="pnl_outer placeholder-glow pnl_list_wrap">
          <div className="pnl_mdlpnl">
            <div className="post_item_outer">
              <div className="post_item">
                <div className="activity_innr">
                  <div className="people_row groups_item">
                    <div className="d-flex justify-content-between gap-3 align-items-center corporation_wrapper w-100 flex-wrap">
                      <div className="search_left w-75 position-relative">
                        <input
                          type="text"
                          className="form-control rounded-90 text-gray"
                          placeholder="Search"
                          value={searchQuery}
                          onChange={(e) => setSearchQuery(e.target.value)}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              getAllFaqs();
                            }
                          }}
                        />
                        <button className="srch_btn" onClick={getAllFaqs}>
                          <span className="material-icons-outlined">
                            search
                          </span>
                        </button>
                      </div>
                      <div className="serch_label_right">
                        <ul className="list_stye_none d-flex align-iems-center gap-2">
                          <li className="dropdown">
                            <Link
                              to="#"
                              className="btn outline"
                              onClick={() => {
                                setResetQuery(true);
                                setSearchQuery("");
                              }}
                            >
                              <span className="material-icons-outlined">
                                refresh
                              </span>
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="#"
                              data-bs-toggle="modal"
                              data-bs-target="#questionFaqModal"
                              className="btn btn-sm"
                            >
                              Ask Questions
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex align-items-center justify-content-end mb-2">
                    <p>Found {faqList.length} result </p>
                  </div>

                  <div ref={faqListRef} name="scrollBlogRefDiv" />

                  {faqList.length > 0 &&
                    faqList.map((faq, index) => {
                      return (
                        <div className="people_row groups_item" key={index}>
                          <div className="d-flex justify-content-between gap-3 align-items-center corporation_wrapper w-100 flex-wrap">
                            <div className="corporation_left">
                              <h3 className="fs-lg text-black mb-3 fw-semibold">
                                {faq.question}
                              </h3>
                              {/* <div className="event_tags d-flex align-items-center gap-2  flex-wrap">
                                <span className="bg-light text-primary fs-sm rounded-90 px-3 py-2">
                                  Groups
                                </span>
                                <label className="text-gray fw-semibold mb-0">
                                  Tags
                                </label>
                                <ul className="list_stye_none mb-0">
                                  <li>
                                    <a href="javascript:void(0);">Lobees</a>{" "}
                                  </li>
                                  <li>
                                    <a href="javascript:void(0);">SportsMoon</a>{" "}
                                  </li>
                                  <li>
                                    <a href="javascript:void(0);">Crowcol</a>{" "}
                                  </li>
                                </ul>
                              </div> */}
                            </div>
                            <div className="corporation_right">
                              <div className="details_btn mt-0">
                                <Link
                                  to={`/faq/details/${faq._id}`}
                                  className="btn outline"
                                >
                                  Open
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
          <AuthContactPanel />
        </div>
      </div>

      <SaveFaqModal />
    </section>
  );
};

export default FaqListBody;
